import { AxiosPromise } from 'axios';
import _ from 'lodash';
import axios from '@/axios';
import { SupplierFields, SupplierParams } from '@/model/suppliers-choices';
import { Contract } from '@/model/contract';

const contractsService = {
    getAll(): AxiosPromise<Contract[]> {
        return axios.get('/contracts');
    },
    getWithParams(supplierParams: SupplierParams): AxiosPromise<Contract[]> {
        const params = new  URLSearchParams({});

        _.each(
            [
                SupplierFields.suppliers,
                SupplierFields.countries,
                SupplierFields.products,
            ],
            (param) => {
                const data = supplierParams[param];
                if (data && data?.length) {
                    params.append(param, data.join(','));
                }
            }
        );

        return axios.get(
            `/contracts`,
            {
                params
            }
        );
    },
}

export default contractsService;