import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66d2fd9f"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["default-button", [_ctx.buttonClass, _ctx.buttonRoleClass]]),
    disabled: _ctx.isLoading || _ctx.disabled
  }, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_spinner, {
          key: 0,
          class: "w-5 mr-2"
        }))
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}