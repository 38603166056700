import { AppMutation } from '@/store/app/app.mutation';
import { Notification } from '@/store/app/notification/notification';

export interface AppState {
    isLoading: boolean,
    notifications: Notification[]
}

export const state: AppState = {
    isLoading: false,
    notifications: []
};

export const actions = {};

export const mutations = {
    [AppMutation.SET_IS_LOADING](state: AppState, isLoading: boolean): void {
        state.isLoading = isLoading;
    },
    [AppMutation.ADD_NOTIFICATION](state: AppState, notification: Notification): void {
        state.notifications = [...state.notifications, notification];
    },
    [AppMutation.REMOVE_NOTIFICATION](state: AppState, notification: Notification): void {
        state.notifications = state.notifications.filter((element: Notification) => element !== notification);
    }
};

export const getters = {
    isLoading(state: AppState): boolean {
        return state.isLoading;
    },
    notifications(state: AppState): Notification[] {
        return state.notifications;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
}