
import { defineComponent } from 'vue';
import NotificationElement from '@/components/ui/notification/NotificationElement.vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: "NotificationsList",
  components: {NotificationElement},
  computed: {
    ...mapGetters(['notifications'])
  }
})
