
import { defineComponent } from 'vue';
import Spinner from '@/components/ui/Spinner.vue';

export default defineComponent({
  name: "OutlineButton",
  components: {Spinner},
  props: {
    type: {
      type: String,
      default: 'default'
    },
    role: {
      type: String,
      default: 'primary'
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonClass(): string {
      switch (this.type) {
        case 'outline':
          return 'default-button--outline';
        case 'empty':
          return 'default-button--empty';
        default:
          return '';
      }
    },
    buttonRoleClass(): string {
      switch (this.role) {
        case 'danger':
          return 'default-button--danger';
        default:
          return '';
      }
    }
  }
})
