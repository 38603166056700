const defaultContent = {
    paragraphs: [],
    introText: {title: '', content: ''},
    generalDisclaimer: {
        title: 'General disclaimer',
        content: 'Confidentiality Note: This email is confidential and may be privileged. If you are not the intended recipient, please notify the sender immediately and delete the email from your computer. You should not copy the email, use it for any purpose or disclose its contents to any other person. '
    },
    outboundDisclaimer: {title: '', content: ''},
    returnsDisclaimer: {title: '', content: ''},
    terms: {title: '', content: ''},
    gdpr: {
        title: 'GDPR', content: `Parcel4you P/S only uses personal data when it is necessary to deliver shipments to private individuals. Therefore, on acceptance of our business terms it is implicitly agreed that Parcel4you P/S collects, processes and stores the recipient’s name, address, telephone number and email address. Parcel4you P/S uses several third parties (sub-processors) for shipping and they exclusively handle personal data on behalf of Parcel4you P/S. According to the third–party data processors (sub-processors), it is our requirement, that they comply with the current personal data laws. Parcel4you P/S has established organizational and technical measures that ensure personal data is not disclosed to unauthorized persons, misused or otherwise treated in violation of the law.
Parcel4you P/S stores personal data within the time-frame set by law after which the data is deleted. The storage period depends on the nature of the personal data and the background for storage. The Personal Data Act gives the right to insight own data stored at Parcel4you P/S. The right of access implies that Parcel4you P/S is required to provide data subjects a copy of their processed personal data upon request of the company where the product was originally purchased.`
    },
};

export default defaultContent;