import { AxiosPromise } from 'axios';
import _ from 'lodash';
import axios from '@/axios';
import { SupplierFields, SuppliersSection } from '@/model/suppliers-choices';
import { Product } from '@/model/product';
import { Disclaimers } from '@/model/proposal-content';

const disclaimersService = {
    getAll(): AxiosPromise<Product[]> {
        return axios.get('/disclaimer-templates');
    },
    getWithParams(supplierSections: SuppliersSection[]): AxiosPromise<Disclaimers> {
        const params = new  URLSearchParams({});

        params.append(
            SupplierFields.suppliers,
            _.compact(
                _.map(
                    supplierSections,
                    (section: SuppliersSection) => {
                        return section.params?.[SupplierFields.suppliers] ?? null;
                    }
                )
            ).join(',')
        );

        return axios.get(
            `/disclaimer-templates`,
            {
                params
            }
        );
    },
}

export default disclaimersService;