import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70539770"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loader-label" }
const _hoisted_2 = { class: "ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_spinner, { class: "w-5 h-5" }),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.label)), 1)
  ]))
}