import { AxiosPromise } from 'axios';
import axios from '@/axios';
import { NewProposalRequest } from '@/model/request/new-proposal.request';
import { ProposalCustomer } from '@/model/proposal-customer';
import { ProposalSalePrice } from '@/model/proposal-sale-price';
import { ProposalCostPrice } from '@/model/proposal-cost-price';
import { UpdateProposalRequest } from '@/model/request/update-proposal.request';
import { ProposalExportType } from '@/model/proposal-export-type';
import { ProposalStatus } from '@/model/proposal-status';
import { SupplierParams } from '@/model/suppliers-choices';
import { ProposalProductable } from '@/model/proposal-productable';

const proposalsService = {
    create(customerId: string, proposal: NewProposalRequest): AxiosPromise<ProposalSalePrice> {
        return axios.post(`/customers/${customerId}/proposals`, proposal)
    },
    update(proposalId: string, data: UpdateProposalRequest): AxiosPromise<ProposalSalePrice> {
        return axios.put(`/proposals/${proposalId}`, data);
    },
    getList(): AxiosPromise<ProposalCustomer[]> {
        return axios.get(`/proposals`)
    },
    repopulate(proposalId: string): AxiosPromise<ProposalSalePrice> {
      return axios.post(`/proposals/${proposalId}/repopulate`)
    },
    destroy(proposalId: string): AxiosPromise<void> {
      return axios.delete(`/proposals/${proposalId}`);
    },
    getCostPrices(customerId: string, selectedSuppliers: SupplierParams[]): AxiosPromise<ProposalCostPrice> {
        return axios.get(
            `/customers/${customerId}/proposals/new`,
            {
                params: {
                    suppliers: selectedSuppliers,
                }
            })
    },
    updateSalePrices(customerId: string, proposalId: string,  selectedSuppliers: SupplierParams[]): AxiosPromise<ProposalProductable> {
        return axios.get(
            `/customers/${customerId}/proposals/new`,
            {
                params: {
                    proposalId,
                    suppliers: selectedSuppliers,
                }
            })
    },
    getOne(proposalId: string): AxiosPromise<ProposalSalePrice> {
        return axios.get(`/proposals/${proposalId}`);
    },
    getForReuse(proposalId: string, loadAllProducts: boolean, keepSalePrices: boolean): AxiosPromise<ProposalSalePrice> {
      return axios.get(`/proposals/${proposalId}/reuse?load_all_available_products=${loadAllProducts}&keep_prices=${keepSalePrices}`);
    },
    export(proposalId: string, type: ProposalExportType): AxiosPromise<string> {
        return axios.get(`/proposals/${proposalId}/export/${type}`, {
            responseType: 'blob', // had to add this one here
        });
    },
    changeStatus(proposalId: string, status: ProposalStatus): AxiosPromise<ProposalSalePrice> {
        return axios.put(`/proposals/${proposalId}/change-status/${status}`);
    }
}

export default proposalsService;
