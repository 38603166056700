<template>
  <app-loader v-if="isLoading"/>

  <Nav
    @logoutClick="showLogoutModal = true"
  />

  <div class="px-2 mx-auto mb-10">
    <router-view/>
  </div>

  <div class="wrapper w-full md:w-4/5">
    <modal
      type="warning"
      v-model="showLogoutModal"
      @confirmClick="handleLogoutConfirmClick"
    >
      <template v-slot:title>{{ $t("auth.logout_modal.title") }}</template>

      {{ $t("auth.logout_modal.content") }}
    </modal>
  </div>

  <notifications-list/>
</template>

<script>
import Nav from '@/components/ui/Nav';
import { defineComponent, inject } from 'vue';
import AppLoader from '@/components/ui/AppLoader';
import Modal from '@/components/ui/Modal.vue'
import { mapGetters } from 'vuex';
import NotificationsList from '@/components/ui/notification/NotificationsList';

export default defineComponent({
  components: { NotificationsList, AppLoader, Nav, Modal },
  setup() {
    return {
      keycloak: inject('keycloak'),
    }
  },
  computed: {
    ...mapGetters(['isLoading'])
  },
  data() {
    return {
      showLogoutModal: false,
    };
  },
  methods: {
    handleLogoutConfirmClick() {
      this.keycloak.logout();
    },
  },
})
</script>
