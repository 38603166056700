import {
    createRouter,
    createWebHistory,
    RouteRecordRaw,
    RouterView,
} from 'vue-router';
import PriceLists from '@/views/price-lists/PriceLists.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '',
        name: 'dashboard',
        redirect: '/proposals'
    },
    {
        path: '/customers',
        component: RouterView,
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "group-clients" */ '@/views/customer/CustomersList.vue'),
                name: 'customers.list'
            },
            {
                path: 'create',
                component: () => import(/* webpackChunkName: "group-clients" */ '@/views/customer/CustomerCreate.vue'),
                name: 'customers.create'
            }
        ]
    },
    {
        path: '/price-lists',
        component: PriceLists,
        name: 'price_lists'
    },
    {
        path: '/proposals',
        component: RouterView,
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "group-proposals" */ '@/views/proposal/ProposalsList.vue'),
                name: 'proposal.list'
            },
            {
                path: '/select-customer',
                component: () => import(/* webpackChunkName: "group-proposals" */ '@/views/proposal/ProposalClient.vue'),
                name: 'proposal.form.select_client',
            },
            {
                path: ':clientId/send/:proposalId',
                component: () => import(/* webpackChunkName: "group-proposals" */ '@/views/proposal/ProposalSend.vue'),
                name: 'proposal.send',
            },
            {
                path: ':clientId/show/:proposalId',
                component: () => import(/* webpackChunkName: "group-proposals" */ '@/views/proposal/ProposalShow.vue'),
                name: 'proposal.show',
                redirect: {
                    name: 'proposal.show.outbound'
                },
                children: [
                    {
                        path: '',
                        component: () => import(/* webpackChunkName: "group-proposals" */ '@/views/proposal/proposal-form/ProposalOutbound.vue'),
                        name: 'proposal.show.outbound'
                    },
                    {
                        path: 'returns',
                        component: () => import(/* webpackChunkName: "group-proposals" */ '@/views/proposal/proposal-form/ProposalReturns.vue'),
                        name: 'proposal.show.returns'
                    },
                    {
                        path: 'surcharges',
                        component: () => import(/* webpackChunkName: "group-proposals" */ '@/views/proposal/proposal-form/ProposalSurcharges.vue'),
                        name: 'proposal.show.surcharges'
                    },
                    {
                        path: 'content',
                        component: () => import(/* webpackChunkName: "group-proposals" */ '@/views/proposal/proposal-form/ProposalContent.vue'),
                        name: 'proposal.show.content'
                    }
                ]
            },
            {
                path: ':clientId/form',
                component: () => import(/* webpackChunkName: "group-proposals" */ '@/views/proposal/ProposalCreate.vue'),
                name: 'proposal.form',
                redirect: {
                    name: 'proposal.form.outbound'
                },
                children: [
                    {
                        path: '',
                        component: () => import(/* webpackChunkName: "group-proposals" */ '@/views/proposal/proposal-form/ProposalOutbound.vue'),
                        name: 'proposal.form.outbound'
                    },
                    {
                        path: 'returns',
                        component: () => import(/* webpackChunkName: "group-proposals" */ '@/views/proposal/proposal-form/ProposalReturns.vue'),
                        name: 'proposal.form.returns'
                    },
                    {
                        path: 'surcharges',
                        component: () => import(/* webpackChunkName: "group-proposals" */ '@/views/proposal/proposal-form/ProposalSurcharges.vue'),
                        name: 'proposal.form.surcharges'
                    },
                    {
                        path: 'content',
                        component: () => import(/* webpackChunkName: "group-proposals" */ '@/views/proposal/proposal-form/ProposalContent.vue'),
                        name: 'proposal.form.content'
                    }
                ]
            },
        ]
    },
    {
        path: '/admin',
        name: 'admin',
        component: () => import('@/views/admin/AdminPanel.vue')
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
