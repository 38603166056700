import {AxiosPromise} from "axios";
import {Supplier} from "@/model/supplier";
import axios from "@/axios";

const suppliersService = {
    getAll(): AxiosPromise<Supplier[]> {
        return axios.get<Supplier[]>('/suppliers');
    },
    getWithParams(customerId: string): AxiosPromise<Supplier[]> {
        return axios.get<Supplier[]>(
            `/suppliers`,
            {
                params: {
                    customerId,
                }
            }
        );
    },
}

export default suppliersService;