
import { defineComponent, PropType } from 'vue';
import { NotificationType } from '@/store/app/notification/notification-type';
import { Notification } from '@/store/app/notification/notification';
import { AppMutation } from '@/store/app/app.mutation';

interface NotificationElementState {
  show: boolean;
  computedWidth: number;
  progressInterval: undefined | number;
}

export default defineComponent({
  name: "NotificationElement",
  data(): NotificationElementState {
    return {
      show: false,
      computedWidth: 100,
      progressInterval: undefined
    }
  },
  props: {
    notification: {
      type: Object as PropType<Notification>,
      required: true,
    }
  },
  mounted() {
    this.show = true;
    setTimeout(() => this.show = false, 3000);
    setTimeout(() => this.$store.commit(AppMutation.REMOVE_NOTIFICATION, this.notification), 4000);

    this.progressInterval = setInterval(() => {
      this.computedWidth = this.computedWidth - 1;
    }, 30)
  },
  methods: {
    handleCloseClick(): void {
      this.show = false;
      setTimeout(() => this.$store.commit(AppMutation.REMOVE_NOTIFICATION, this.notification), 1000);
    }
  },
  unmounted(): void {
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
    }
  },
  computed: {
    details(): { className: string, iconClass: string } {
      switch (this.notification.type) {
        case NotificationType.INFO:
          return {
            className: 'notification--info',
            iconClass: 'icon-info'
          }
        case NotificationType.WARNING:
          return {
            className: 'notification--warning',
            iconClass: 'icon-warning'
          }
        case NotificationType.ERROR:
          return {
            className: 'notification--error',
            iconClass: 'icon-error'
          }
        case NotificationType.SUCCESS:
        default:
          return {
            className: 'notification--success',
            iconClass: 'icon-success'
          }
      }
    }
  }
})
