import { AxiosPromise } from 'axios';
import _ from 'lodash';
import axios from '@/axios';
import { SupplierFields, SupplierParams } from '@/model/suppliers-choices';
import { Product } from '@/model/product';

const productsService = {
    getAll(): AxiosPromise<Product[]> {
        return axios.get('/products');
    },
    getWithParams(supplierParams: SupplierParams): AxiosPromise<Product[]> {
        const params = new  URLSearchParams({});

        _.each(
            [
                SupplierFields.suppliers,
                SupplierFields.countries,
            ],
            (param) => {
                const data = supplierParams[param];
                if (data && data?.length) {
                    params.append(param, data.join(','));
                }
            }
        );

        return axios.get(
            `/products`,
            {
                params
            }
        );
    },
}

export default productsService;