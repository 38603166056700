// Temporary place to store disclaimers content for RETURNS parcels. The content is loaded in relation to supplier name.

const returnsDisclaimersContentMock: {[supplier: string]: string} = {
  'B2C Europe': `<p>Valid for 2023</p><h3>Free to Consumer</h3><p></p><ol><li>Return solution where the consumer can return free of charge at the local post office</li><li class="ql-indent-1">Returns via local postoffice – in the country of return</li><li class="ql-indent-1">Consumer receives a pre-paid return label</li><li class="ql-indent-1">Fast and reliable delivery times to local B2C Europe warehouses</li><li class="ql-indent-1">Maximum size 60x40x40cm and maximum weight 20 kilograms</li><li class="ql-indent-1">Includes Track & Trace</li></ol><p></p><h3>Paid by Consumer</h3><p></p><ol><li>Return solution where the consumer pays local shipping costs</li><li class="ql-indent-1">Consumer pays for the delivery costs to a local B2C Europe warehouse</li><li class="ql-indent-1">Parcels receive a scan in the local B2C Europe warehouse</li><li class="ql-indent-1">Maximum size 60x40x40cm and maximum weight 20 kilograms</li><li class="ql-indent-1">Includes Track & Trace</li></ol><p></p>`,
  'DHL SE': `<p>Valid for 2023</p><p>Return fee for returns and undeliverables is 5 SEK / shipment</p>`,
  'PostNL': `<p>Valid for 2023</p><p>Returns rates are equal to the outbound rates. </p>`,
  'Posti FI': `<p>Valid for 2023</p><p>Returns rates are equal to the outbound rates. </p>`,
  'EVRI / Hermes UK': `<p>Returns rates are equal to the outbound rates. </p>`,
  'P2P': `<p>Returns rates are equal to the outbound rates. </p>`,
  'Packeta': `<p>Returns rates are equal to the outbound rates. </p>`,
  'Packeta (P2P)': `<p>Returns rates are equal to the outbound rates. </p>`,
  'Packeta (Coolrunner)': `<p>Returns rates are equal to the outbound rates. </p>`,
  'DAO': `<p>Valid for 2023</p><p>Returns rates are equal to the outbound rates. </p>`
}

export default returnsDisclaimersContentMock;
