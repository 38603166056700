export enum ProposalAction {
    LOAD_PROPOSAL_SUPPLIERS_CHOICES = 'PROPOSAL_LOAD_SUPPLIERS_CHOICES_ACTION',
    LOAD_PROPOSAL_AVAILABLE_SUPPLIERS = 'PROPOSAL_LOAD_AVAILABLE_SUPPLIERS_ACTION',
    LOAD_PROPOSAL_AVAILABLE_COUNTRIES = 'PROPOSAL_LOAD_AVAILABLE_COUNTRIES_ACTION',
    LOAD_PROPOSAL_AVAILABLE_PRODUCTS = 'PROPOSAL_LOAD_AVAILABLE_PRODUCTS_ACTION',
    LOAD_PROPOSAL_AVAILABLE_CONTRACTS = 'PROPOSAL_LOAD_AVAILABLE_CONTRACTS_ACTION',
    LOAD_PROPOSAL_AVAILABLE_SERVICES = 'PROPOSAL_LOAD_AVAILABLE_SERVICES_ACTION',
    GET_SALE_PRICES_ROWS = 'PROPOSAL_GET_SALE_PRICES_ROWS',
    UPDATE_SALE_PRICES_ROWS = 'PROPOSAL_UPDATE_SALE_PRICES_ROWS',
    LOAD_SALE_PRICES_ROWS_FOR_PREVIEW = 'PROPOSAL_LOAD_SALE_PRICES_ROWS_FOR_PREVIEW_ACTION',
    SAVE_NEW_PROPOSAL = 'PROPOSAL_SAVE_NEW_PROPOSAL_ACTION',
    UPDATE_PROPOSAL = 'PROPOSAL_UPDATE_PROPOSAL_ACTION',
    LOAD_SALE_PRICES_ROWS_FOR_REUSING_PROPOSAL = 'PROPOSAL_LOAD_SALE_PRICES_ROWS_FOR_REUSING_PROPOSAL_ACTION',
    CHANGE_STATUS = 'PROPOSAL_CHANGE_STATUS_ACTION',
    REPOPULATE = 'PROPOSAL_REPOPULATE_ACTION',
    FETCH_LIST_ELEMENTS = 'PROPOSAL_FETCH_LIST_ELEMENTS_ACTION',
    EXPORT_PROPOSAL = 'PROPOSAL_EXPORT_PROPOSAL_ACTION',
    DELETE_PROPOSAL = 'PROPOSAL_DELETE_PROPOSAL_ACTION'
}
