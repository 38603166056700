
import {defineComponent, inject} from 'vue';
import router from '@/router';
import Keycloak from "keycloak-js";
import _ from 'lodash';

export default defineComponent({
  name: 'Nav',
  props: ['show'],
  emits: ['logoutClick'],
  setup() {
    const keycloak = inject('keycloak') as Keycloak.KeycloakInstance;

    const userRoles = _.get(keycloak, 'tokenParsed.resource_access.parcel4you-web-app.roles', []) as string[];

    return {
      userAdmin: userRoles.includes('user_admin') || userRoles.includes('super_admin'),
    }
  },
  methods: {
    handleAdminPanelClick() {
      router.push({ name: 'admin' });
    },
  }
});
