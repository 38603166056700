import instance from 'axios';
import applyCaseMiddleware from 'axios-case-converter';

const axios = applyCaseMiddleware(instance.create());

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(function (config) {
    config.headers = {
        ...config.headers, ...{
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json'
        }
    };

    return config;
});

export default axios;