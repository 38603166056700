import _ from 'lodash';
import { ProposalMutation } from '@/store/proposal/proposal.mutation';
import { Proposal } from '@/model/proposal';
import { ProposalStatus } from '@/model/proposal-status';
import { ProposalAction } from '@/store/proposal/proposal.action';
import proposalsService from '@/services/proposals.service';
import { ActionContext } from 'vuex';
import { RootState } from '@/store';
import { SalePrice } from '@/model/sale-price';
import { ProposalSalePrice } from '@/model/proposal-sale-price';
import { Paragraph } from '@/store/proposal/content/paragraph';
import { AxiosResponse } from 'axios';
import proposalsMapper from '@/utils/proposals.mapper';
import { ProposalContent } from '@/model/proposal-content';
import outboundDisclaimersContentMock from '@/mocks/outbound-disclaimers-content.mock';
import returnsDisclaimersContentMock from '@/mocks/returns-disclaimers-content.mock';
import { ProposalCustomer } from '@/model/proposal-customer';
import { ProposalExportType } from '@/model/proposal-export-type';
import defaultContent from '@/mocks/default-content.mock';
import suppliersService from '@/services/suppliers.service';
import {
    StringifySupplierParams,
    SupplierFields, SupplierParams,
    SuppliersChoices,
    SuppliersSectionSavingData,
    SuppliersSelectOption,
} from '@/model/suppliers-choices';
import { Supplier } from '@/model/supplier';
import countriesService from '@/services/countries.service';
import productsService from '@/services/products.service';
import contractsService from '@/services/contracts.service';
import servicesService from '@/services/services.service';
import disclaimersService from '@/services/disclaimers.service';
import { ProductSalePrice } from '@/model/product-sale-price';
import { ProductCostPrice } from '@/model/product-cost-price';

interface ParagraphMutationData {
    newParagraph: Paragraph,
    oldParagraph: Paragraph
}

export interface SalePriceColumn {
    isFirst: boolean;
    weightBracket: SalePrice;
}

export interface SalePriceRow {
    id: string;
    country: string;
    supplier: string;
    product: string;
    contract: string;
    service: string;
    description?: string,
    weightBrackets: Map<number, SalePriceColumn>;
    selected: boolean;
    grossMargin: number;
    productId: string;
    new?: boolean;
}

export interface ReducedSalePriceRow {
    prices: SalePrice[],
    description?: string,
    new?: true,
}

export interface ProposalState {
    suppliersChoices: SuppliersChoices;
    showGrossMargin: boolean;
    showCostPrice: boolean;
    displayList: boolean;
    proposal: Proposal;
    formProcessing: boolean;
    blockGrossMargin: boolean;
    weightBrackets: Set<number>;
    salePricesRows: SalePriceRow[];
    returnsWeightBrackets: Set<number>;
    returnsRows: SalePriceRow[];
    surchargesRows: SalePriceRow[];
    isLoading: boolean;
    content: ProposalContent;
    isProcessing: boolean;
    proposalList: ProposalCustomer[];
}

export const state: ProposalState = {
    suppliersChoices: {
        availableSuppliers: [],
        suppliers: [],
    },
    showGrossMargin: true,
    showCostPrice: true,
    displayList: false,
    formProcessing: false,
    proposal: {
        id: undefined,
        createdAt: '',
        updatedAt: '',
        sentAt: '',
        approvedAt: '',
        rejectedAt: '',
        validFrom: '',
        validTo: '',
        updatedBy: '',
        status: ProposalStatus.DRAFT,
        description: '',
        suppliers: ''
    },
    isProcessing: false,
    blockGrossMargin: true,
    weightBrackets: new Set<number>(),
    salePricesRows: [],
    returnsWeightBrackets: new Set<number>(),
    returnsRows: [],
    surchargesRows: [],
    isLoading: false,
    content: {...defaultContent},
    proposalList: [],
};

export const actions = {
    [ProposalAction.DELETE_PROPOSAL](
        context: ActionContext<ProposalState, RootState>,
        proposalId: string
    ): Promise<AxiosResponse<void>> {
        context.commit(ProposalMutation.SET_IS_PROCESSING, true);

        return proposalsService
            .destroy(proposalId)
            .finally(() => context.commit(ProposalMutation.SET_IS_PROCESSING, false))
    },
    [ProposalAction.EXPORT_PROPOSAL](
        context: ActionContext<ProposalState, RootState>,
        data: { proposalId: string, type: ProposalExportType }
    ): Promise<AxiosResponse<string>> {
        context.commit(ProposalMutation.SET_IS_PROCESSING, true);

        return proposalsService.export(data.proposalId, data.type)
            .then(response => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(new Blob([response.data]));
                link.setAttribute('download', `Proposal - ${data.proposalId}.${data.type.toLowerCase()}`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                return response;
            })
            .finally(() => context.commit(ProposalMutation.SET_IS_PROCESSING, false))
    },
    [ProposalAction.FETCH_LIST_ELEMENTS](
        context: ActionContext<ProposalState, RootState>
    ): Promise<AxiosResponse<ProposalCustomer[]>> {
        context.commit(ProposalMutation.SET_IS_PROCESSING, true);

        return proposalsService
            .getList()
            .then(response => {
                context.commit(ProposalMutation.SET_PROPOSAL_LIST, response.data)
                return response;
            })
            .finally(() => context.commit(ProposalMutation.SET_IS_PROCESSING, false));
    },
    [ProposalAction.CHANGE_STATUS](
        context: ActionContext<ProposalState, RootState>,
        data: { proposalId: string, status: ProposalStatus }
    ): Promise<AxiosResponse<ProposalSalePrice>> {
        context.commit(ProposalMutation.SET_IS_PROCESSING, true);

        return proposalsService.changeStatus(data.proposalId, data.status)
            .finally(() => context.commit(ProposalMutation.SET_IS_PROCESSING, false));
    },
    [ProposalAction.REPOPULATE](
        context: ActionContext<ProposalState, RootState>,
        data: { proposalId: string }
    ): Promise<AxiosResponse<ProposalSalePrice>> {
       context.commit(ProposalMutation.SET_IS_PROCESSING, true);

      return proposalsService.repopulate(data.proposalId)
          .finally(() => context.commit(ProposalMutation.SET_IS_PROCESSING, false));
    },
    async [ProposalAction.LOAD_PROPOSAL_AVAILABLE_SUPPLIERS](
        {commit}: ActionContext<ProposalState, RootState>,
        customerId: string
    ): Promise<void> {
        return suppliersService
            .getWithParams(customerId)
            .then(({data}) => {
                commit(
                    ProposalMutation.SET_PROPOSAL_AVAILABLE_SUPPLIERS,
                    _.map(
                        data,
                        (supplierData)=> ({
                            id: supplierData.id.toString(),
                            name: supplierData.fullName,
                        })
                    )
                );
            });
    },
    async [ProposalAction.LOAD_PROPOSAL_AVAILABLE_COUNTRIES](
        {state, commit}: ActionContext<ProposalState, RootState>,
        index: number
    ): Promise<void> {
        return countriesService
            .getWithParams(state.suppliersChoices.suppliers[index].params)
            .then(({data})=> {
                commit(
                    ProposalMutation.SET_PROPOSAL_AVAILABLE_COUNTRIES,
                    {
                        index,
                        countries: _.map(
                            data,
                            (countryData)=> ({
                                id: countryData.id?.toString(),
                                name: countryData.name,
                            })
                        ),
                    }
                );
                commit(
                    ProposalMutation.SAVE_PROPOSAL_SUPPLIERS_SECTION,
                    {
                        index,
                        values: {
                            ...state.suppliersChoices.suppliers[index].params,
                            [SupplierFields.countries]: _.filter(
                                state.suppliersChoices.suppliers[index].params[SupplierFields.countries],
                                (id) => _.includes(_.map(data, (country) => country.id?.toString()), id.toString()),
                            )
                        },
                    }
                );
            });
    },
    async [ProposalAction.LOAD_PROPOSAL_AVAILABLE_PRODUCTS](
        {state, commit}: ActionContext<ProposalState, RootState>,
        index: number
    ): Promise<void> {
        return productsService
            .getWithParams(state.suppliersChoices.suppliers[index].params)
            .then(({data})=> {
                commit(
                    ProposalMutation.SET_PROPOSAL_AVAILABLE_PRODUCTS,
                    {
                        index,
                        products: _.map(
                            data,
                            (productData)=> ({
                                id: productData.name,
                                name: productData.name,
                            })
                        ),
                    }
                );
                commit(
                    ProposalMutation.SAVE_PROPOSAL_SUPPLIERS_SECTION,
                    {
                        index,
                        values: {
                            ...state.suppliersChoices.suppliers[index].params,
                            [SupplierFields.products]: _.filter(
                                state.suppliersChoices.suppliers[index].params[SupplierFields.products],
                                (id) => _.includes(_.map(data, 'name'), id)
                            )
                        },
                    }
                );
            });
    },
    async [ProposalAction.LOAD_PROPOSAL_AVAILABLE_CONTRACTS](
        {state, commit}: ActionContext<ProposalState, RootState>,
        index: number
    ): Promise<void> {
        return contractsService
            .getWithParams(state.suppliersChoices.suppliers[index].params)
            .then(({data})=> {
                commit(
                    ProposalMutation.SET_PROPOSAL_AVAILABLE_CONTRACTS,
                    {
                        index,
                        contracts: _.map(
                            data,
                            (contractData)=> ({
                                id: contractData.name,
                                name: contractData.name,
                            })
                        ),
                    }
                );
                commit(
                    ProposalMutation.SAVE_PROPOSAL_SUPPLIERS_SECTION,
                    {
                        index,
                        values: {
                            ...state.suppliersChoices.suppliers[index].params,
                            [SupplierFields.contracts]: _.filter(
                                state.suppliersChoices.suppliers[index].params[SupplierFields.contracts],
                                (id) => _.includes(_.map(data, 'name'), id)
                            )
                        },
                    }
                );
            });
    },
    async [ProposalAction.LOAD_PROPOSAL_AVAILABLE_SERVICES](
        {state, commit}: ActionContext<ProposalState, RootState>,
        index: number
    ): Promise<void> {
        return servicesService
            .getWithParams(state.suppliersChoices.suppliers[index].params)
            .then(({data})=> {
                commit(
                    ProposalMutation.SET_PROPOSAL_AVAILABLE_SERVICES,
                    {
                        index,
                        services: _.map(
                            data,
                            (serviceData)=> ({
                                id: serviceData.name,
                                name: serviceData.name,
                            })
                        ),
                    }
                );
                commit(
                    ProposalMutation.SAVE_PROPOSAL_SUPPLIERS_SECTION,
                    {
                        index,
                        values: {
                            ...state.suppliersChoices.suppliers[index].params,
                            [SupplierFields.services]: _.filter(
                                state.suppliersChoices.suppliers[index].params[SupplierFields.services],
                                (id) => _.includes(_.map(data, 'name'), id)
                            )
                        },
                    }
                );
            });
    },
    async [ ProposalAction.LOAD_PROPOSAL_SUPPLIERS_CHOICES ](
        {commit, dispatch}: ActionContext<ProposalState, RootState>,
        data: {
            customerId: string;
            suppliersSelection: StringifySupplierParams[];
        }
    ): Promise<void> {
        commit(ProposalMutation.CLEAR_PROPOSAL_SUPPLIERS_CHOICES);
        await dispatch(ProposalAction.LOAD_PROPOSAL_AVAILABLE_SUPPLIERS, data.customerId)
        _.each(data.suppliersSelection, async (params, index) => {
            commit(ProposalMutation.ADD_PROPOSAL_SUPPLIERS_SECTION);
            commit(ProposalMutation.SAVE_PROPOSAL_SUPPLIERS_SECTION, {
                index,
                values: _.reduce(
                    params,
                    (
                        preparedValues,
                        stringValues,
                        key
                    ) => {
                        const values = stringValues?.split(',');
                        if (values?.length) {
                            preparedValues[ key as SupplierFields ] = values;
                        }
                        return preparedValues;
                    },
                    {} as SupplierParams
                ),
            });
            await dispatch(ProposalAction.LOAD_PROPOSAL_AVAILABLE_COUNTRIES, index);
            await dispatch(ProposalAction.LOAD_PROPOSAL_AVAILABLE_PRODUCTS, index);
            await dispatch(ProposalAction.LOAD_PROPOSAL_AVAILABLE_CONTRACTS, index);
            await dispatch(ProposalAction.LOAD_PROPOSAL_AVAILABLE_SERVICES, index);
        });
    },
    [ProposalAction.SAVE_NEW_PROPOSAL](
        {state}: ActionContext<ProposalState, RootState>,
        customerId: string
    ): Promise<AxiosResponse<ProposalSalePrice>> {
        return proposalsService
            .create(customerId, {
                validFrom: state.proposal.validFrom,
                validTo: state.proposal.validTo,
                description: state.proposal.description || '',
                productList: proposalsMapper.mapProductListPrices(state.salePricesRows, state.returnsRows, state.surchargesRows),
                suppliers: proposalsMapper.mapSuppliersParams(state.suppliersChoices.suppliers),
                content: state.content
            });
    },
    [ProposalAction.UPDATE_PROPOSAL]({state}: ActionContext<ProposalState, RootState>, proposalId: string): Promise<AxiosResponse<ProposalSalePrice>> {
        return proposalsService
            .update(proposalId, {
                validFrom: state.proposal.validFrom,
                validTo: state.proposal.validTo,
                description: state.proposal.description || '',
                productList: proposalsMapper.mapUpdateProductsListPrices(state.salePricesRows, state.returnsRows, state.surchargesRows),
                suppliers: proposalsMapper.mapSuppliersParams(state.suppliersChoices.suppliers),
                content: state.content
            })
    },
    [ProposalAction.LOAD_SALE_PRICES_ROWS_FOR_PREVIEW]({commit, dispatch}: ActionContext<ProposalState, RootState>, ids: Record<string, string>): void {
        commit(ProposalMutation.SET_IS_LOADING, true);

        proposalsService
            .getOne(ids.proposalId)
            .then(async ({data}) => {
                const weightBrackets = proposalsMapper.mapSalePriceBrackets(data.products.outbound);
                const returnsBrackets = proposalsMapper.mapSalePriceBrackets(data.products.returns);

                commit(ProposalMutation.SET_WEIGHT_BRACKETS, weightBrackets);
                commit(ProposalMutation.SET_RETURNS_WEIGHT_BRACKETS, returnsBrackets);
                commit(ProposalMutation.SET_PROPOSAL, data);
                commit(ProposalMutation.SET_PROPOSAL_CONTENT, data.content);
                commit(ProposalMutation.SET_PROPOSAL_VALID_TO, data.validTo);
                commit(ProposalMutation.SET_PROPOSAL_VALID_FROM, data.validFrom);

                await dispatch(ProposalAction.LOAD_PROPOSAL_SUPPLIERS_CHOICES, {
                    customerId: ids.customerId,
                    suppliersSelection: data.suppliersSelection
                });

                commit(
                    ProposalMutation.SET_SALE_PRICES_ROWS,
                    proposalsMapper.mapSalePricesProducts(data.products.outbound, weightBrackets, data.status)
                );

                commit(
                    ProposalMutation.SET_RETURNS_SALE_PRICES_ROWS,
                    proposalsMapper.mapSalePricesProducts(data.products.returns, returnsBrackets, data.status)
                );

                commit(
                    ProposalMutation.SET_SURCHARGES_SALE_PRICES_ROWS,
                    proposalsMapper.mapSalePricesSurcharges(data.products.surcharges)
                );

            })
            .finally(() => commit(ProposalMutation.SET_IS_LOADING, false));
    },
    [ProposalAction.LOAD_SALE_PRICES_ROWS_FOR_REUSING_PROPOSAL]({commit}: ActionContext<ProposalState, RootState>, data: { proposalId: string, loadAllProducts: boolean, keepSalePrices: boolean }): void {
        commit(ProposalMutation.SET_IS_LOADING, true);

        proposalsService
            .getForReuse(data.proposalId, data.loadAllProducts, data.keepSalePrices)
            .then(({data}) => {
                const weightBrackets = proposalsMapper.mapSalePriceBrackets(data.products.outbound);
                const returnsBrackets = proposalsMapper.mapSalePriceBrackets(data.products.returns);

                commit(ProposalMutation.SET_WEIGHT_BRACKETS, weightBrackets);
                commit(ProposalMutation.SET_RETURNS_WEIGHT_BRACKETS, returnsBrackets);
                commit(ProposalMutation.SET_PROPOSAL_CONTENT, data.content);
                commit(ProposalMutation.SET_PROPOSAL_VALID_TO, data.validTo);
                commit(ProposalMutation.SET_PROPOSAL_VALID_FROM, data.validFrom);

                commit(
                    ProposalMutation.SET_SALE_PRICES_ROWS,
                    proposalsMapper.mapSalePricesProducts(data.products.outbound, weightBrackets, data.status)
                );

                commit(
                    ProposalMutation.SET_RETURNS_SALE_PRICES_ROWS,
                    proposalsMapper.mapSalePricesProducts(data.products.returns, returnsBrackets, data.status)
                );

                commit(
                    ProposalMutation.SET_SURCHARGES_SALE_PRICES_ROWS,
                    proposalsMapper.mapSalePricesSurcharges(data.products.surcharges)
                );

                commit(ProposalMutation.SET_PROPOSAL_CONTENT, {...defaultContent});

                commit(
                    ProposalMutation.SET_CONTENT_OUTBOUND_DISCLAIMERS,
                    {
                        newParagraph: {
                            title: 'Outbound prices',
                            content: proposalsMapper.mapDisclaimers(data.products.outbound, outboundDisclaimersContentMock)
                        }
                    }
                );

                commit(
                    ProposalMutation.SET_CONTENT_RETURNS_DISCLAIMERS,
                    {
                        newParagraph: {
                            title: 'Return prices',
                            content: proposalsMapper.mapDisclaimers(data.products.returns, returnsDisclaimersContentMock)
                        }
                    }
                );
            })
            .finally(() => commit(ProposalMutation.SET_IS_LOADING, false));
    },
    [ProposalAction.UPDATE_SALE_PRICES_ROWS]({commit}: ActionContext<ProposalState, RootState>, ids: Record<string, string>): void {
        commit(ProposalMutation.SET_IS_LOADING, true);

        proposalsService
            .updateSalePrices(
                ids.customerId,
                ids.proposalId,
                proposalsMapper.mapSuppliersParams(state.suppliersChoices.suppliers)
            )
            .then(async ({data}) => {
                const weightBrackets = proposalsMapper.mapSalePriceBrackets(data.products.outbound);
                const returnsBrackets = proposalsMapper.mapSalePriceBrackets(data.products.returns);

                commit(ProposalMutation.SET_WEIGHT_BRACKETS, weightBrackets);
                commit(ProposalMutation.SET_RETURNS_WEIGHT_BRACKETS, returnsBrackets);
                commit(ProposalMutation.SET_PROPOSAL, data);
                commit(ProposalMutation.SET_PROPOSAL_VALID_TO, data.validTo);
                commit(ProposalMutation.SET_PROPOSAL_VALID_FROM, data.validFrom);

                commit(
                    ProposalMutation.SET_SALE_PRICES_ROWS,
                    [
                        ...proposalsMapper.mapSalePricesProducts(
                            data.products.outbound.filter(
                                (product) => product.new === undefined
                            ) as ProductSalePrice[],
                            weightBrackets,
                            data.status
                        ),
                        ...proposalsMapper.mapCostPricesProducts(
                            data.products.outbound.filter(
                                (product) => product.new === true
                            ) as ProductCostPrice[],
                            weightBrackets
                        ),
                    ]
                );
                commit(
                    ProposalMutation.SET_RETURNS_SALE_PRICES_ROWS,
                    [
                        ...proposalsMapper.mapSalePricesProducts(
                            data.products.returns.filter(
                                (product) => product.new === undefined
                            ) as ProductSalePrice[],
                            weightBrackets,
                            data.status
                        ),
                        ...proposalsMapper.mapCostPricesProducts(
                            data.products.returns.filter(
                                (product) => product.new === true
                            ) as ProductCostPrice[],
                            weightBrackets
                        ),
                    ]
                );
                commit(
                    ProposalMutation.SET_SURCHARGES_SALE_PRICES_ROWS,
                    [
                        ...proposalsMapper.mapSalePricesSurcharges(
                            data.products.surcharges.filter(
                                (product) => product.new === undefined
                            ) as ProductSalePrice[],
                        ),
                        ...proposalsMapper.mapCostPricesSurcharges(
                            data.products.surcharges.filter(
                                (product) => product.new === true
                            ) as ProductCostPrice[]
                        ),
                    ]
                );

                const disclaimers = await disclaimersService.getWithParams(state.suppliersChoices.suppliers);

                commit(
                    ProposalMutation.SET_CONTENT_OUTBOUND_DISCLAIMERS,
                    {
                        newParagraph: {
                            title: 'Outbound prices',
                            content: proposalsMapper.mapDynamicDisclaimers(disclaimers.data.outbound),
                        }
                    }
                );

                commit(
                    ProposalMutation.SET_CONTENT_RETURNS_DISCLAIMERS,
                    {
                        newParagraph: {
                            title: 'Return prices',
                            content: proposalsMapper.mapDynamicDisclaimers(disclaimers.data.return),
                        }
                    }
                );

            })
            .finally(() => commit(ProposalMutation.SET_IS_LOADING, false));
    },
    [ProposalAction.GET_SALE_PRICES_ROWS]({commit, state}: ActionContext<ProposalState, RootState>, customerId: string): void {
        commit(ProposalMutation.SET_IS_LOADING, true);
        proposalsService
            .getCostPrices(
                customerId,
                proposalsMapper.mapSuppliersParams(state.suppliersChoices.suppliers)
            )
            .then(async ({data}) => {
                const weightBrackets = proposalsMapper.mapCostPriceBrackets(data.outbound);
                const returnsBrackets = proposalsMapper.mapCostPriceBrackets(data.returns);

                commit(ProposalMutation.SET_WEIGHT_BRACKETS, weightBrackets);
                commit(ProposalMutation.SET_RETURNS_WEIGHT_BRACKETS, returnsBrackets);
                commit(ProposalMutation.SET_PROPOSAL_VALID_TO, data.defaults.validTo);
                commit(ProposalMutation.SET_PROPOSAL_VALID_FROM, data.defaults.validFrom);

                commit(
                    ProposalMutation.SET_SALE_PRICES_ROWS,
                    proposalsMapper.mapCostPricesProducts(data.outbound, weightBrackets)
                );
                commit(
                    ProposalMutation.SET_RETURNS_SALE_PRICES_ROWS,
                    proposalsMapper.mapCostPricesProducts(data.returns, returnsBrackets)
                );
                commit(
                    ProposalMutation.SET_SURCHARGES_SALE_PRICES_ROWS,
                    proposalsMapper.mapCostPricesSurcharges(data.surcharges)
                );

                commit(ProposalMutation.SET_PROPOSAL_CONTENT, {...defaultContent});

                const disclaimers = await disclaimersService.getWithParams(state.suppliersChoices.suppliers);

                commit(
                    ProposalMutation.SET_CONTENT_OUTBOUND_DISCLAIMERS,
                    {
                        newParagraph: {
                            title: 'Outbound prices',
                            content: proposalsMapper.mapDynamicDisclaimers(disclaimers.data.outbound),
                        }
                    }
                );

                commit(
                    ProposalMutation.SET_CONTENT_RETURNS_DISCLAIMERS,
                    {
                        newParagraph: {
                            title: 'Return prices',
                            content: proposalsMapper.mapDynamicDisclaimers(disclaimers.data.return),
                        }
                    }
                );
            })
            .finally(() => commit(ProposalMutation.SET_IS_LOADING, false));
    }
};

export const mutations = {
    [ProposalMutation.SET_PROPOSAL_DESCRIPTION](state: ProposalState, description: string): void {
        state.proposal.description = description;
    },
    [ProposalMutation.SET_PROPOSAL_VALID_FROM](state: ProposalState, validFrom: string): void {
        state.proposal.validFrom = validFrom;
    },
    [ProposalMutation.SET_PROPOSAL_VALID_TO](state: ProposalState, validTo: string): void {
        state.proposal.validTo = validTo
    },
    [ProposalMutation.SET_IS_PROCESSING](state: ProposalState, isProcessing: boolean): void {
        state.isProcessing = isProcessing;
    },
    [ProposalMutation.SET_SALE_PRICES_ROWS](state: ProposalState, rows: SalePriceRow[]): void {
        state.salePricesRows = rows;
    },
    [ProposalMutation.SET_RETURNS_SALE_PRICES_ROWS](state: ProposalState, rows: SalePriceRow[]): void {
        state.returnsRows = rows;
    },
    [ProposalMutation.SET_SURCHARGES_SALE_PRICES_ROWS](state: ProposalState, rows: SalePriceRow[]): void {
        state.surchargesRows = rows;
    },
    [ProposalMutation.SET_GROSS_MARGIN](state: ProposalState, showGrossMargin: boolean): void {
        state.showGrossMargin = showGrossMargin;
    },
    [ProposalMutation.SET_COST_PRICE](state: ProposalState, showCostPrice: boolean): void {
        state.showCostPrice = showCostPrice;
    },
    [ProposalMutation.SET_DISPLAY_LIST](state: ProposalState, displayList: boolean): void {
        state.displayList = displayList;
    },
    [ProposalMutation.SET_FORM_PROCESSING](state: ProposalState, isProcessing: boolean): void {
        state.formProcessing = isProcessing;
    },
    [ProposalMutation.SET_BLOCK_GROSS_MARGIN](state: ProposalState, isBlocked: boolean): void {
        state.blockGrossMargin = isBlocked;
    },
    [ProposalMutation.SET_WEIGHT_BRACKETS](state: ProposalState, brackets: Set<number>): void {
        state.weightBrackets = brackets;
    },
    [ProposalMutation.SET_RETURNS_WEIGHT_BRACKETS](state: ProposalState, brackets: Set<number>): void {
        state.returnsWeightBrackets = brackets;
    },
    [ProposalMutation.ADD_CONTENT_PARAGRAPH](state: ProposalState, paragraph: Paragraph): void {
        state.content.paragraphs = [...state.content.paragraphs, paragraph];
    },
    [ProposalMutation.SET_IS_LOADING](state: ProposalState, isLoading: boolean): void {
        state.isLoading = isLoading;
    },
    [ProposalMutation.REMOVE_SALE_PRICES_ROW](state: ProposalState): void {
        state.salePricesRows = state.salePricesRows.filter(row => !row.selected);
        state.returnsRows = state.returnsRows.filter(row => !row.selected);
    },
    [ProposalMutation.SET_PROPOSAL_AVAILABLE_SUPPLIERS](state: ProposalState, suppliers: Supplier[]): void {
        state.suppliersChoices.availableSuppliers = suppliers;
    },
    [ProposalMutation.SET_PROPOSAL_AVAILABLE_COUNTRIES](state: ProposalState, data: {index: number; countries: SuppliersSelectOption[]}): void {
        state.suppliersChoices.suppliers[data.index].availableOptions[SupplierFields.countries] = data.countries;
    },
    [ProposalMutation.SET_PROPOSAL_AVAILABLE_PRODUCTS](state: ProposalState, data: {index: number; products: SuppliersSelectOption[]}): void {
        state.suppliersChoices.suppliers[data.index].availableOptions[SupplierFields.products] = data.products;
    },
    [ProposalMutation.SET_PROPOSAL_AVAILABLE_CONTRACTS](state: ProposalState, data: {index: number; contracts: SuppliersSelectOption[]}): void {
        state.suppliersChoices.suppliers[data.index].availableOptions[SupplierFields.contracts] = data.contracts;
    },
    [ProposalMutation.SET_PROPOSAL_AVAILABLE_SERVICES](state: ProposalState, data: {index: number; services: SuppliersSelectOption[]}): void {
        state.suppliersChoices.suppliers[data.index].availableOptions[SupplierFields.services] = data.services;
    },
    [ProposalMutation.ADD_PROPOSAL_SUPPLIERS_SECTION](state: ProposalState): void {
        state.suppliersChoices.suppliers.push({
            params: {},
            availableOptions: {},
        });
    },
    [ProposalMutation.REMOVE_PROPOSAL_SUPPLIERS_SECTION](state: ProposalState, sectionIndex: number): void {
        state.suppliersChoices.suppliers.splice(sectionIndex, 1);
    },
    [ProposalMutation.SAVE_PROPOSAL_SUPPLIERS_SECTION](state: ProposalState, sectionData: SuppliersSectionSavingData): void {
        state.suppliersChoices.suppliers[sectionData.index].params = { ...sectionData.values };
    },
    [ProposalMutation.CLEAR_PROPOSAL_SUPPLIERS_CHOICES](state: ProposalState): void {
        state.suppliersChoices = {
            availableSuppliers: [],
            suppliers: [],
        };
    },
    [ProposalMutation.SET_PROPOSAL](state: ProposalState, proposal: Proposal): void {
        state.proposal = proposal;
    },
    [ProposalMutation.CLEAR_PROPOSAL](state: ProposalState): void {
        state.proposal = {
            id: undefined,
            createdAt: '',
            updatedAt: '',
            sentAt: '',
            approvedAt: '',
            rejectedAt: '',
            validFrom: '',
            validTo: '',
            updatedBy: '',
            description: '',
            status: ProposalStatus.DRAFT,
            suppliers: ''
        };
    },
    [ProposalMutation.CLEAR_SALE_PRICES](state: ProposalState): void {
        state.salePricesRows = [];
    },
    [ProposalMutation.SETUP_GROSS_MARGIN](state: ProposalState, grossMargin: number): void {
        state.salePricesRows.forEach(
            row => row.weightBrackets.forEach(column => {
                column.weightBracket.grossMargin = grossMargin

                const amount = column.weightBracket.costPrice.amount;
                column.weightBracket.price.amount = Math.round(amount * (grossMargin + 100) / 100);
            })
        );
        state.returnsRows.forEach(
            row => row.weightBrackets.forEach(column => {
                column.weightBracket.grossMargin = grossMargin

                const amount = column.weightBracket.costPrice.amount;
                column.weightBracket.price.amount = Math.round(amount * (grossMargin + 100) / 100);
            })
        );
        state.surchargesRows.forEach(
            row => row.weightBrackets.forEach(column => {
                column.weightBracket.grossMargin = grossMargin

                const amount = column.weightBracket.costPrice.amount;
                column.weightBracket.price.amount = Math.round(amount * (grossMargin + 100) / 100);
            })
        );
    },
    [ProposalMutation.SETUP_OUTBOUND_GROSS_MARGIN](state: ProposalState, grossMargin: number): void {
        state.salePricesRows.forEach(
            row => row.weightBrackets.forEach(column => column.weightBracket.grossMargin = grossMargin)
        );
    },
    [ProposalMutation.SETUP_RETURNS_GROSS_MARGIN](state: ProposalState, grossMargin: number): void {
        state.returnsRows.forEach(
            row => row.weightBrackets
                .forEach(column => column.weightBracket.grossMargin = grossMargin)
        );
    },
    [ProposalMutation.SETUP_SURCHARGES_GROSS_MARGIN](state: ProposalState, grossMargin: number): void {
        state.surchargesRows.forEach(
            row => row.weightBrackets
                .forEach(column => column.weightBracket.grossMargin = grossMargin)
        );
    },
    [ProposalMutation.SET_CONTENT_INTRO_TEXT](state: ProposalState, data: ParagraphMutationData): void {
        state.content.introText = data.newParagraph;
    },
    [ProposalMutation.SET_CONTENT_GENERAL_DISCLAIMER](state: ProposalState, data: ParagraphMutationData): void {
        state.content.generalDisclaimer = data.newParagraph;
    },
    [ProposalMutation.SET_CONTENT_OUTBOUND_DISCLAIMERS](state: ProposalState, data: ParagraphMutationData): void {
        state.content.outboundDisclaimer = data.newParagraph;
    },
    [ProposalMutation.SET_CONTENT_RETURNS_DISCLAIMERS](state: ProposalState, data: ParagraphMutationData): void {
        state.content.returnsDisclaimer = data.newParagraph;
    },
    [ProposalMutation.SET_CONTENT_TERMS](state: ProposalState, data: ParagraphMutationData): void {
        state.content.terms = data.newParagraph;
    },
    [ProposalMutation.REMOVE_PARAGRAPH](state: ProposalState, paragraph: Paragraph): void {
        state.content.paragraphs = state.content.paragraphs.filter(element => element !== paragraph);
    },
    [ProposalMutation.CHANGE_PARAGRAPH](state: ProposalState, data: ParagraphMutationData): void {
        state.content.paragraphs = state.content.paragraphs.map(paragraph => {
            if (paragraph === data.oldParagraph) {
                return data.newParagraph;
            }

            return paragraph;
        })
    },
    [ProposalMutation.SET_CONTENT_GDPR](state: ProposalState, data: ParagraphMutationData): void {
        state.content.gdpr = data.newParagraph;
    },
    [ProposalMutation.SET_PROPOSAL_CONTENT](state: ProposalState, content: ProposalContent): void {
        state.content = content;
    },
    [ProposalMutation.SET_PROPOSAL_LIST](state: ProposalState, elements: ProposalCustomer[]): void {
        state.proposalList = elements;
    }
};

export const getters = {
    showGrossMargin(state: ProposalState): boolean {
        return state.showGrossMargin;
    },
    showCostPrice(state: ProposalState): boolean {
        return state.showCostPrice;
    },
    suppliersChoices(state: ProposalState): SuppliersChoices {
        return state.suppliersChoices;
    },
    proposal(state: ProposalState): Proposal {
        return state.proposal;
    },
    blockGrossMargin(state: ProposalState): boolean {
        return state.blockGrossMargin;
    },
    salePricesRows(state: ProposalState): SalePriceRow[] {
        return state.salePricesRows;
    },
    weightBrackets(state: ProposalState): Set<number> {
        return state.weightBrackets;
    },
    isProposalLoading(state: ProposalState): boolean {
        return state.isLoading;
    },
    introText(state: ProposalState): Paragraph {
        return state.content.introText;
    },
    generalDisclaimer(state: ProposalState): Paragraph {
        return state.content.generalDisclaimer;
    },
    outboundDisclaimers(state: ProposalState): Paragraph {
        return state.content.outboundDisclaimer;
    },
    returnsDisclaimers(state: ProposalState): Paragraph {
        return state.content.returnsDisclaimer;
    },
    terms(state: ProposalState): Paragraph {
        return state.content.terms;
    },
    gdpr(state: ProposalState): Paragraph {
        return state.content.gdpr
    },
    returnsRows(state: ProposalState): SalePriceRow[] {
        return state.returnsRows;
    },
    returnsWeightBrackets(state: ProposalState): Set<number> {
        return state.returnsWeightBrackets;
    },
    surchargesRows(state: ProposalState): SalePriceRow[] {
        return state.surchargesRows;
    },
    paragraphs(state: ProposalState): Paragraph[] {
        return state.content.paragraphs;
    },
    isProposalEditable(state: ProposalState): boolean {
        return ProposalStatus.DRAFT === state.proposal.status.toLowerCase();
    },
    isProposalsProcessing(state: ProposalState): boolean {
        return state.isProcessing;
    },
    proposalList(state: ProposalState): ProposalCustomer[] {
        return state.proposalList;
    },
    proposalValidFrom(state: ProposalState): string | null {
        return state.proposal.validFrom;
    },
    proposalValidTo(state: ProposalState): string | null {
        return state.proposal.validTo;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
}
