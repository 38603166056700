import { createStore } from 'vuex'
import proposalModule, { ProposalState } from './proposal/proposal.module';
import appModule, { AppState } from './app/app.module';

export type RootState = ProposalState | AppState;

export default createStore({
  modules: {
    proposalModule,
    appModule
  }
})
