
import { defineComponent } from 'vue';
import Spinner from '@/components/ui/Spinner.vue';

export default defineComponent({
  name: "LoaderLabel",
  components: {Spinner},
  props: {
    label: {
      type: String,
      default: 'general.loading'
    },
  }
})
