import _ from 'lodash';
import { ProductCostPrice } from '@/model/product-cost-price';
import { WeightBracketPrice } from '@/model/weight-bracket-price';
import { ProductSalePrice } from '@/model/product-sale-price';
import { ReducedSalePriceRow, SalePriceColumn, SalePriceRow } from '@/store/proposal/proposal.module';
import { WeightBracketSalePrice } from '@/model/weight-bracket-sale-price';
import { NewProposalProductList } from '@/model/request/new-proposal.request';
import { UpdateProductList } from '@/model/request/update-proposal.request';
import { ProposalStatus } from '@/model/proposal-status';
import { SupplierParams, SuppliersSection } from '@/model/suppliers-choices';
import { Disclaimer } from '@/model/proposal-content';

type Productable = ProductCostPrice | ProductSalePrice;

const proposalsMapper = {
    mapCostPriceBrackets(products: ProductCostPrice[]): Set<number> {
        return new Set(
            products
                .reduce((carry: number[], product: ProductCostPrice) => [...carry, ...product.costPrices.map((costPrice: WeightBracketPrice) => costPrice.weightMax)], [])
                .sort((a: number, b: number) => a - b)
        );
    },
    mapSalePriceBrackets(products: Productable[]): Set<number> {
        return new Set(
            products
                .reduce((carry: number[], product: Productable) => {
                    if ('new' in product && product?.new === true) {
                        return [ ...carry, ...product.costPrices.map((costPrice: WeightBracketPrice) => costPrice.weightMax) ];
                    }
                    return [ ...carry, ...product.salePrices.map((costPrice: WeightBracketPrice) => costPrice.weightMax) ];
                }, [])
                .sort((a: number, b: number) => a - b)
        );
    },
    mapCostPricesProducts(products: ProductCostPrice[], brackets: Set<number>): SalePriceRow[] {
        return products
            .map((product: ProductCostPrice): SalePriceRow => {
                let previousBracket = 0;

                return {
                    id: product.id,
                    country: product.country.name,
                    supplier: product.supplier,
                    product: product.name,
                    contract: product.contract,
                    service: product.service,
                    productId: product.id,
                    weightBrackets: Array.from(brackets)
                        .reduce((carry: Map<number, SalePriceColumn>, bracket: number) => {
                            product.costPrices.forEach((costPrice: WeightBracketPrice) => {
                                if (bracket > costPrice.weightMin && bracket <= costPrice.weightMax) {
                                    carry.set(bracket, {
                                        isFirst: 0 === carry.size,
                                        weightBracket: {
                                            priceId: costPrice.priceId,
                                            weightMin: previousBracket,
                                            weightMax: bracket,
                                            price: {
                                                currency: costPrice.price.currency,
                                                amount: costPrice.price.amount
                                            },
                                            costPrice: costPrice.price,
                                            grossMargin: 0,
                                            productId: product.id
                                        }
                                    });
                                }
                            })

                            previousBracket = bracket;
                            return carry;
                        }, new Map<number, SalePriceColumn>()),
                    selected: false,
                    grossMargin: 0,
                    new: product?.new ?? undefined,
                }
            })
    },
    mapCostPricesSurcharges(products: ProductCostPrice[]): SalePriceRow[] {
        return products
            .map((product: ProductCostPrice): SalePriceRow => {
                const weightBrackets = new Map<number, SalePriceColumn>();
                weightBrackets.set(0, {
                    isFirst: true,
                    weightBracket: {
                        priceId: product.costPrices[0].priceId,
                        weightMin: product.costPrices[0].weightMin,
                        weightMax: product.costPrices[0].weightMax,
                        price: {
                            currency: product.costPrices[0].price.currency,
                            amount: product.costPrices[0].price.amount
                        },
                        costPrice: product.costPrices[0].price,
                        grossMargin: 0,
                        productId: product.id
                    }
                });

                return {
                    id: product.id,
                    country: product.country.name,
                    supplier: product.supplier,
                    product: product.name,
                    contract: product.contract,
                    service: product.service,
                    productId: product.id,
                    description: product.description,
                    weightBrackets,
                    selected: false,
                    grossMargin: 0,
                    new: product?.new ?? undefined,
                }
            })
    },
    mapSalePricesProducts(
        products: ProductSalePrice[],
        brackets: Set<number>,
        proposalStatus: ProposalStatus
    ): SalePriceRow[] {
        return products
            .map((product: ProductSalePrice): SalePriceRow => ({
                id: product.id,
                country: product.country.name,
                supplier: product.supplier,
                product: product.name,
                contract: product.contract,
                service: product.service,
                productId: product.id,
                weightBrackets: Array.from(brackets)
                    .reduce((carry: Map<number, SalePriceColumn>, bracket: number) => {

                        product.salePrices.forEach((costPrice: WeightBracketSalePrice) => {
                            const condition = ProposalStatus.DRAFT === proposalStatus
                                ? bracket > costPrice.weightMin && bracket <= costPrice.weightMax
                                : bracket === costPrice.weightMax;

                            if (condition) {
                                carry.set(bracket, {
                                    isFirst: 0 === carry.size,
                                    weightBracket: {
                                        priceId: costPrice.priceId,
                                        weightMin: costPrice.weightMin,
                                        weightMax: costPrice.weightMax,
                                        price: {
                                            currency: costPrice.price.currency,
                                            amount: costPrice.price.amount
                                        },
                                        costPrice: costPrice.costPrice,
                                        grossMargin: costPrice.grossMargin,
                                        productId: product.id
                                    }
                                });
                            }
                        })

                        return carry;
                    }, new Map<number, SalePriceColumn>()),
                selected: false,
                grossMargin: 0,
            }));
    },
    mapSalePricesSurcharges(
        products: ProductSalePrice[]
    ): SalePriceRow[] {
        return products
            .map((product: ProductSalePrice): SalePriceRow => {
                const weightBrackets = new Map<number, SalePriceColumn>();
                weightBrackets.set(0, {
                    isFirst: true,
                    weightBracket: {
                        priceId: product.salePrices[0].priceId,
                        weightMin: product.salePrices[0].weightMin,
                        weightMax: product.salePrices[0].weightMax,
                        price: {
                            currency: product.salePrices[0].price.currency,
                            amount: product.salePrices[0].price.amount
                        },
                        costPrice: product.salePrices[0].costPrice,
                        grossMargin: product.salePrices[0].grossMargin,
                        productId: product.id
                    }
                });
                return {
                    id: product.id,
                    country: product.country.name,
                    supplier: product.supplier,
                    product: product.name,
                    contract: product.contract,
                    service: product.service,
                    productId: product.id,
                    description: product.description,
                    weightBrackets,
                    selected: false,
                    grossMargin: 0
                }
            });
    },
    mapProductListPrices(outbound: SalePriceRow[], returns: SalePriceRow[], surcharges: SalePriceRow[]): NewProposalProductList[] {
        const outboundRows = this.mapGroupedPrices(outbound);
        const returnsRows = this.mapGroupedPrices(returns);
        const surchargesRows = this.mapGroupedPrices(surcharges);

        return [
            ...Object.keys(outboundRows)
                .map(key => ({
                    productId: key,
                    prices: outboundRows[key].prices.map(price => ({
                        costPriceId: price.priceId,
                        grossMargin: price.grossMargin,
                        price: price.price.amount,
                        weightMin: price.weightMin,
                        weightMax: price.weightMax
                    }))
                })),
            ...Object.keys(returnsRows)
                .map(key => ({
                    productId: key,
                    prices: returnsRows[key].prices.map(price => ({
                        costPriceId: price.priceId,
                        grossMargin: price.grossMargin,
                        price: price.price.amount,
                        weightMin: price.weightMin,
                        weightMax: price.weightMax
                    }))
                })),
            ...Object.keys(surchargesRows)
                .map(key => ({
                    productId: key,
                    description: surchargesRows[key].description,
                    prices: surchargesRows[key].prices.map(price => ({
                        costPriceId: price.priceId,
                        grossMargin: price.grossMargin,
                        price: price.price.amount,
                        weightMin: price.weightMin,
                        weightMax: price.weightMax
                    }))
                }))
        ]
    },
    mapGroupedPrices(rows: SalePriceRow[]): { [key: string]: ReducedSalePriceRow } {
        return rows
            .reduce((carry, row: SalePriceRow) => {
                Array.from(row.weightBrackets.values())
                    .map(column => column.weightBracket)
                    .forEach(price => {
                        carry[price.productId] = carry[price.productId] || { prices: [] }
                        carry[price.productId].prices.push(price)
                        if (row.description) {
                            carry[ price.productId ].description = row.description
                        }
                        if (row.new) {
                            carry[ price.productId ].new = row.new
                        }
                    });
                return carry;
            }, Object.create(null) as { [productId: string]: ReducedSalePriceRow })
    },
    mapUpdateProductsListPrices(outbound: SalePriceRow[], returns: SalePriceRow[], surcharges: SalePriceRow[]): UpdateProductList[] {
        const outboundRows = this.mapGroupedPrices(outbound);
        const returnsRows = this.mapGroupedPrices(returns);
        const surchargesRows = this.mapGroupedPrices(surcharges);

        return [
            ...Object.keys(outboundRows)
                .map(key => ({
                    productId: key,
                    prices: outboundRows[key].prices.map(price => {
                        if (outboundRows[key].new) {
                            return {
                                costPriceId: price.priceId,
                                grossMargin: price.grossMargin,
                                price: price.price.amount,
                                weightMin: price.weightMin,
                                weightMax: price.weightMax
                            }
                        }
                        return {
                            priceId: price.priceId,
                            grossMargin: price.grossMargin,
                            price: price.price.amount,
                        }
                    })
                })),
            ...Object.keys(returnsRows)
                .map(key => ({
                    productId: key,
                    prices: returnsRows[key].prices.map(price => {
                        if (returnsRows[key].new) {
                            return {
                                costPriceId: price.priceId,
                                grossMargin: price.grossMargin,
                                price: price.price.amount,
                                weightMin: price.weightMin,
                                weightMax: price.weightMax
                            }
                        }
                        return {
                            priceId: price.priceId,
                            grossMargin: price.grossMargin,
                            price: price.price.amount,
                        }
                    })
                })),
            ...Object.keys(surchargesRows)
                .map(key => ({
                    productId: key,
                    description: surchargesRows[key].description,
                    prices: surchargesRows[key].prices.map(price => {
                        if (surchargesRows[key].new) {
                            return {
                                costPriceId: price.priceId,
                                grossMargin: price.grossMargin,
                                price: price.price.amount,
                                weightMin: price.weightMin,
                                weightMax: price.weightMax
                            }
                        }
                        return {
                            priceId: price.priceId,
                            grossMargin: price.grossMargin,
                            price: price.price.amount,
                        }
                    })
                }))
        ]
    },
    mapDisclaimers(products: Productable[], contentMocks: { [p: string]: string }): string {
        return Array.from(
            new Set(
                products
                    .map(product => [product.supplier?.trim(), `${product.supplier?.trim()}|${product.name?.trim()}`])
                    .reduce((carry, element) => [...carry, ...element], [])
            )
        )
            .map(key => {
                const content = contentMocks[key];

                if (!content) {
                    return '';
                }

                const parts = key.split('|');

                return `<h2>${parts[0]}</h2>${content}`;
            })
            .reduce((carry, element) => carry + element, '')
    },
    mapDynamicDisclaimers(disclaimers: Disclaimer[]): string {
        return _.map(
            disclaimers,
            (disclaimer) => {
                return `<h2>${disclaimer.fullName}</h2>${disclaimer.body}`;
            }
        ).join('<br/>');
    },
    mapSuppliersParams(suppliers: SuppliersSection[]) : SupplierParams[] {
        return _.reduce(
            _.map(suppliers, 'params'),
            (preparedParamsArray, params) => {
                const preparedParams = _.omitBy(
                    _.mapValues(
                        params,
                        (values) => {
                            return values?.join(',');
                        }
                    ),
                    _.isEmpty
                ) as SupplierParams;
                if (!_.isEmpty(preparedParams)) {
                    preparedParamsArray.push(preparedParams)
                }
                return preparedParamsArray;
            },
            [] as SupplierParams[]
        )
    },
}

export default proposalsMapper;