import { AxiosPromise, AxiosResponse } from 'axios';
import { GroupedCountries } from '@/model/grouped-countries';
import { Country } from '@/model/country';
import axios from '@/axios';
import { SupplierFields, SupplierParams } from '@/model/suppliers-choices';
import _ from 'lodash';

const countriesService = {
    getCountries(): AxiosPromise<Country[]> {
        return axios.get('/countries');
    },
    getWithParams(supplierParams: SupplierParams): AxiosPromise<Country[]> {
        const params = new  URLSearchParams({});

        _.each(
            [
                SupplierFields.suppliers,
            ],
            (param) => {
                const data = supplierParams[param];
                if (data && data?.length) {
                    params.append(param, data.join(','));
                }
            }
        );

        return axios.get(
            `/countries`,
            {
                params
            }
        );
    },
    getGroupedCountries(): AxiosPromise<GroupedCountries[]> {
        return new Promise<AxiosResponse<GroupedCountries[]>>(resolve => {
            resolve({
                data: [
                    {
                        groupCode: 'EU',
                        countries: [
                            {
                                code: 'PL',
                                name: 'Poland',
                                id: '1'
                            },
                            {
                                code: 'NL',
                                name: 'Netherlands',
                                id: '2'
                            },
                        ]
                    },
                    {
                        groupCode: 'REST',
                        countries: [
                            {
                                code: 'DE',
                                name: 'Germany',
                                id: '3'
                            },
                            {
                                code: 'RU',
                                name: 'Russia',
                                id: '4'
                            },
                        ]
                    }
                ],
                status: 200,
                statusText: 'OK',
                headers: [],
                config: {}
            })
        })

        // return axios.get<GroupedCountries[]>('/countries/groups')
    }
}

export default countriesService;